

const API =  {
    BASE_URL:process.env.NODE_ENV === 'development'? "https://farmaze-backend-cc71c9c71a27.herokuapp.com/": process.env.REACT_APP_BACKEND_API_BASE_URL,
    BASE_URL1:"https://farmaze-analytics-d83a4b531d22.herokuapp.com/",
    // BASE_URL:process.env.NODE_ENV === 'development'? "https://backend.api.testing.farmaze.com/": process.env.REACT_APP_BACKEND_API_BASE_URL,
    login:"api/v1/auth/login",
    products:"api/v1/products/",
    adminProducts:"api/v1/admin/products/",
    placeOrder:"api/v1/admin/orders",
    orderList:"api/v1/b2bclients/orders",
    invoices:"api/v1/b2bclients/invoices",
    productTrends:"api/v1/admin/products/trends",
    getCurrentUser:"api/v1/admin/users/profile",
};

export {API};