import React, { useState } from "react";
import { connect } from "react-redux";
import withRouter from "../Pages/Router/withRouter";


function CusDateRange(props) {


    
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  
  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const handleFromdateChange = (e) => {
    const newFromDate = e.target.value;
    setFromDate(newFromDate);

    if (new Date(newFromDate) >= new Date(toDate)) {
      const updatedTodate = new Date(newFromDate);
      updatedTodate.setDate(updatedTodate.getDate() + 1);
      setToDate(formatDate(updatedTodate));
    }
  };

  const handleTodateChange = (e) => {
    const newToDate = e.target.value;
    setToDate(newToDate);

    if (new Date(newToDate) <= new Date(fromDate)) {
      const updatedFromdate = new Date(newToDate);
      updatedFromdate.setDate(updatedFromdate.getDate() - 1);
      setFromDate(formatDate(updatedFromdate));
    }
  };
  const onApply = () => {
    props.onApply(fromDate, toDate);
  }


    return (<div className="reservation-box col-lg-12">
        <div className="static common-div-section">
            <div className="input-container h-25">
              <label htmlFor="fromdate">From Date</label>
              <input
                type="date"
                id="fromdate"
                value={fromDate}
                onChange={handleFromdateChange}
              />
            </div>
          </div>
          <div className="flex common-div-section">
            <div className="input-container h-25">
              <label htmlFor="todate">To Date</label>
              <input
                type="date"
                id="todate"
                value={toDate}
                onChange={handleTodateChange}
              />
            </div>
          </div>
          {/* <div className="flexSSSS">
            <button className="button-section greyButton">Clear</button>
          </div> */}
          <div className="flex">
            <button onClick={onApply}  className="button-section">
              Apply
            </button>
          </div>
      </div>);
}


function mapDispatchToProps(dispatch) {
    return {
      dispatch,
    };
  }
  
  function mapStateToProps(state) {
    return {
    //   sellingPrices: state.procurement.sellingPrices??{}
  
    };
  }
  
  export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(CusDateRange)
  );