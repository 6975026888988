import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import withRouter from "../Router/withRouter";
import { MainHeader } from "../../Components/header";
import Sidebar from "../../Components/sidebar";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
// import Clientdetails from "./clientdetails";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Checkbox from "@mui/material/Checkbox";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { MenuItem, Select, FormControl, InputLabel } from "@mui/material";
import {
  getAdminProducts,
  getProductsTrends,
} from "../../services/Actions/client";
import Productpricetrends from "../Auth/productpricetrends.js";
import NoItems from "../Components/no_items.js";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

function Products(props) {
  const [rows, setRows] = useState([]);
  const [value, setValue] = useState("");

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  useEffect(() => {
    props.dispatch(getAdminProducts());
    props.dispatch(getProductsTrends());
    console.log("pros", props);
  }, []);
  useEffect(() => {
    setRows(props.products);
    // setTrends(props.trends);
  }, [props]);

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value);
    setPage(1); // Reset to the first page when rows per page changes
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <div className="w-full">
      {/* <div className="headerandsidebar">
        <MainHeader />
        <Sidebar />
      </div> */}
      <div className="main-container productscontainersection">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2 className="importantheader">PRICE TRENDS</h2>
            </div>
            <div className="col-12 mb-5">
              <Productpricetrends />
            </div>
            <div
              class="col-12 d-flex justify-content-end align-items-center"
              style={{ marginBottom: "20px" }}
            >
              <div className="col-6">
                <h3 className="primaryheader mb-0">Product List</h3>
              </div>
              <div className="col-6 text-end">
                <button
                  onClick={() =>
                    props.router.navigate("/dashboard/product/create")
                  }
                  className="nav-button-section active"
                >
                  ADD PRODUCT
                </button>
              </div>
            </div>

            {rows.filter((item) =>
              value
                ? item.name.toLowerCase().includes(value.toLowerCase())
                : true
            ).length > 0 ? (
              <div>
                <div className="col-lg-12 d-flex align-items-center justify-content-between mb-5">
                  <div className="inputseachsection col-4">
                    <input
                      className="form-control mr-sm-2"
                      type="search"
                      placeholder="Search..."
                      aria-label="Search"
                      onChange={(e) => setValue(e.target.value)}
                    />
                  </div>
                  {/* <div className="d-flex justify-content-end">
                    <Stack spacing={2}>
                      <Pagination
                        count={Math.ceil(
                          rows.filter((item) =>
                            value
                              ? item.name
                                  .toLowerCase()
                                  .includes(value.toLowerCase())
                              : true
                          ).length / rowsPerPage
                        )}
                        page={page}
                        onChange={(e, newPage) => setPage(newPage)}
                        showFirstButton
                        showLastButton
                      />
                    </Stack>
                  </div> */}
                </div>
                <div
                  className="d-flex align-items-center"
                  style={{ marginLeft: "10px" }}
                >
                  <Checkbox {...label} />
                  <h4
                    className="selectalltextsection m-0"
                    style={{ cursor: "pointer" }}
                  >
                    Select all
                  </h4>
                </div>
                <div className="productssection">
                  {/* <Clientdetails /> */}
                  <div className="clientdetailstablesection productstablesection">
                    <TableContainer
                      component={Paper}
                      style={{ borderRadius: "20px" }}
                    >
                      <Table
                        sx={{ minWidth: 650 }}
                        aria-label="simple table"
                        style={{ backgroundColor: "transparent !important" }}
                      >
                        <TableHead>
                          <TableCell align="left"></TableCell>
                          <TableCell align="center">Product</TableCell>
                          <TableCell align="center">Unit</TableCell>
                          <TableCell align="center">Unit Price</TableCell>
                          <TableCell align="center">Availability</TableCell>
                          <TableCell align="center"></TableCell>
                        </TableHead>
                        <TableBody>
                          {rows
                            .filter((item) =>
                              value
                                ? item.name
                                    .toLowerCase()
                                    .includes(value.toLowerCase())
                                : true
                            )
                            .slice((page - 1) * rowsPerPage, page * rowsPerPage)
                            .map((row, index) => (
                              <TableRow
                                key={index}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell align="left">
                                  <Checkbox {...label} />
                                </TableCell>
                                <TableCell align="center">{row.name}</TableCell>
                                <TableCell align="center">
                                  {row?.unit_name ?? ""}
                                </TableCell>
                                <TableCell align="center">
                                  {row?.unit_price ?? ""}
                                </TableCell>
                                <TableCell align="center">
                                  {row?.delivery_window ?? ""}
                                </TableCell>
                                <TableCell align="center">
                                  <div
                                    className="d-flex deleteandediticonsection"
                                    style={{ gap: "20px" }}
                                  >
                                    <i
                                      class="fa fa-trash"
                                      aria-hidden="true"
                                    ></i>
                                    <i
                                      class="material-icons"
                                      onClick={() =>
                                        props.router.navigate(
                                          "/dashboard/product/edit/" + row.id
                                        )
                                      }
                                      style={{ fontSize: "20px" }}
                                    >
                                      edit
                                    </i>
                                  </div>
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    {/* <div className="col-12 text-center my-5">
                <button className="nav-button-section active">
                ADD TO CLIENT LIST
                </button>
              </div> */}
                  </div>
                </div>
                <div className="productscontainerdivsection">
                  <Stack
                    spacing={2}
                    className="d-flex justify-content-between align-items-center flex-row orderandcartsection myorderscontainerdivsection"
                  >
                    <div className="col-6">
                      <FormControl variant="outlined" size="small">
                        <InputLabel>Rows per page</InputLabel>
                        <Select
                          value={rowsPerPage}
                          onChange={handleRowsPerPageChange}
                          label="Rows per page"
                        >
                          <MenuItem value={10}>10</MenuItem>
                          <MenuItem value={20}>20</MenuItem>
                          <MenuItem value={50}>50</MenuItem>
                          <MenuItem value={100}>100</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                    <div className="col-6 d-flex justify-content-end">
                      <Pagination
                        count={Math.ceil(
                          rows.filter((item) =>
                            value
                              ? item.name
                                  .toLowerCase()
                                  .includes(value.toLowerCase())
                              : true
                          ).length / rowsPerPage
                        )}
                        page={page}
                        onChange={handleChangePage}
                        showFirstButton
                        showLastButton
                      />
                    </div>
                  </Stack>
                </div>
              </div>
            ) : (
              <NoItems />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}
function mapStateToProps(state) {
  return {
    clients: state.procurement.clients ?? [],
    products: state.product.products ?? [],
    trends: state.product.productTrends ?? [],
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Products)
);
