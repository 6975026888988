// import service from "service";
import axios from "axios";
import {API} from "../apis"
import service from "./axios_service";
import { toast } from 'react-toastify';




const getYearlySummary = (year, clientId) => {
    let url = `${API.BASE_URL1}sales/yearly/?year=${year}&client_id=${clientId}`;
    return dispatch => {
       return service.get(url)
            .then((res) => {
              
                dispatch({
                    type: "YEARLY_SUMMARY",
                    data: res.data,
                });
                return true
            })
            .catch((error) => {
                dispatch({
                    type: "YEARLY_SUMMARY",
                    data: {},
                });
                return false;
                console.log("error",error);
            })
    }
}



const getDailySummary = (date, clientId) => {
    let url = `${API.BASE_URL1}sales/daily/?date=${date}&client_id=${clientId}`;
    return dispatch => {
         return service.get(url)
            .then((res) => {
              
                dispatch({
                    type: "DAILY_SUMMARY",
                    data: res.data,
                });
                return true
            })
            .catch((error) => {
                dispatch({
                    type: "DAILY_SUMMARY",
                    data: {},
                });
                return false;
                console.log("error",error);
            })
    }
}


const addOprational = (data) => {
    let url = `${API.BASE_URL1}cost/calculate_monthly_fixed`;
    return dispatch => {

       return service.post(url,data)
            .then((res) => {
                toast.success("Added successfully")

              return true;
            })
            .catch((error) => {
                toast.error("Failed to added")

                return false;
            })
    }
}


const getOprationalList = (year) => {
    let url = `${API.BASE_URL1}cost/get_monthly_fixed?year=${year}`;
    return dispatch => {

       return service.get(url)
            .then((res) => {
                dispatch({
                    type: "OPERATIONAL_LIST",
                    data: res.data??[],
                });

              return true;
            })
            .catch((error) => {
                dispatch({
                    type: "OPERATIONAL_LIST",
                    data: [],
                });
                return false;
            })
    }
}




const getDailySalesOverview = (clientId,start,end) => {
    let url = `${API.BASE_URL1}sales/daily-total/?client_id=${clientId}&start_date=${start}&end_date=${end}`;
    return dispatch => {
       return service.get(url)
            .then((res) => {
              
                dispatch({
                    type: "DAILY_SALES_OVERVIEW",
                    data: res.data,
                });
                return true
            })
            .catch((error) => {
                dispatch({
                    type: "DAILY_SALES_OVERVIEW",
                    data: {},
                });
                return false;
                console.log("error",error);
            })
    }
}

export {getYearlySummary,getDailySummary,addOprational,getOprationalList,getDailySalesOverview};
