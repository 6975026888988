import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import withRouter from "../Router/withRouter";

import Table from "react-bootstrap/Table";
import BackIcon from "../../assets/icons/back-icon.svg";
// import CustomYearSelect from "./customyearselect";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import ApexCharts from "apexcharts";
import dayjs from "dayjs";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
// import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { IconButton } from "@mui/material";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import {
  getDailySummary,
  getYearlySummary,
  getDailySalesOverview,
} from "../../services/Actions/insights_operational";
import { getClients } from "../../services/Actions/client";
import moment from "moment/moment";
import NoItems from "../Components/no_items";
import DatePicker from "react-datepicker";
import CusDropDownFilter from "../../Components/cusDropDownFilter";
import CusDateRange from "../../Components/cusDateRange";

dayjs.extend(quarterOfYear);

function DailySalesOverview(props) {
  //  const [rows, setRows] = useState({});
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [dateRange, setDateRange] = useState(7);
  //   const [page, setPage] = useState(1);
  //   const [rowsPerPage, setRowsPerPage] = useState(5);
  //   const [selectedClient, setClients] = useState("");

  useEffect(() => {
    let items = props.dailySalesOverView ?? [];
    let totalR = [];
    let totalC = [];
    let categories = [];
    items.map((i) => {
      categories.push(i.date ?? "");
      totalR.push(i.total_revenue ?? "");
      totalC.push(i.total_cost_value ?? "");
    });
    const highestTotalCost = items.reduce(
      (max, item) => Math.max(max, item.total_cost_value),
      0
    );
    const highestTotalRevenue = items.reduce(
      (max, item) => Math.max(max, item.total_revenue),
      0
    );
    const higherValue = Math.max(highestTotalCost, highestTotalRevenue);
    const salesSummaryOptions = {
      series: [
        {
          name: "Total Revenue",
          data: totalR,
        },
        {
          name: "Total Cost Value",
          data: totalC,
        },
      ],
      chart: {
        type: "line",
        height: 350,
        // background: "#f4f4f4",
        toolbar: {
          show: false,
        },
      },
      colors: ["#FFAA64", "#FF4560"], // Orange and Red
      xaxis: {
        categories: categories,
        title: {
          text: "Date",
          style: {
            fontSize: "14px",
            fontWeight: "bold",
          },
        },
      },
      yaxis: {
        title: {
          text: "Amount",
          style: {
            fontSize: "14px",
            fontWeight: "bold",
          },
        },
        min: 0,
        max: higherValue,
        tickAmount: 5,
        labels: {
          formatter: (val) => val.toLocaleString("en-IN"),
        },
      },
      markers: {
        size: 4,
        strokeColors: ["#FFAA64", "#FF4560"], // Marker colors
      },
      stroke: {
        width: 3,
        curve: "smooth",
      },
      legend: {
        position: "top",
        horizontalAlign: "center",
        markers: {
          width: 12,
          height: 12,
          radius: 12,
        },
      },
      grid: {
        borderColor: "#e0e0e0",
        strokeDashArray: 4,
      },
    };

    const chart = new ApexCharts(
      document.querySelector("#daily-summary-chart"),
      salesSummaryOptions
    );
    // const chart1 = new ApexCharts(document.querySelector("#daily-summary-chart"), salesSummaryOptions);
    // chart1.render();
    chart.render();

    return () => chart.destroy(); // Cleanup chart on component unmount
  }, [props.dailySalesOverView]);

  const handleMonthChange = (date, updateDate) => {
    updateDate(date);
    // fetchData(date);
  };

  const fetchDateRange = (days) => {
    const today = moment();
    const laststart = today.clone().subtract(days, "days");
    const from = laststart.format("YYYY-MM-DD");

    const to = today.format("YYYY-MM-DD");

    apiCalls(from, to);
  };

  const apiCalls = (from, to) => {
    props.dispatch(getDailySalesOverview(props.clientId, from, to));
  };

  const onSelectValue = (val) => {
    if (val != 0) {
      fetchDateRange(val);
    }
    setDateRange(val);
  };

  // const applyDateFilter = (from, to) => {
  //   props.dispatch(getOrders(selectedClient,from,to));

  // }
  return (
    <div>
      <div className="row customdatepickerrowsection">
        <div className="productssection">
          <div className="d-flex">
            <CusDropDownFilter onSelect={onSelectValue} />            
            {/* <div className="fromDateSection align-items-center m-2">
            <DatePicker
              selected={startDate}
              placeholderText="dd/mm/yyyy"
              onChange={(date) => handleMonthChange(date, setStartDate)}
        
              dateFormat="dd/MM/yyyy"
              className="form-control"
            />
          </div>
          <div className="fromDateSection col-3 align-items-center mt-2">
            <DatePicker
              selected={endDate}
              placeholderText="dd/mm/yyyy"
              onChange={(date) => handleMonthChange(date, setEndDate)}
        
              dateFormat="dd/MM/yyyy"
              className="form-control"
            />
          </div>
          <div className="d-flex justify-content-between align-items-center">
              <button onClick={onApply} className="button-section">
                Apply
              </button>
            </div> */}
          </div>
          <div className="d-flex">
          {dateRange == 0 && <CusDateRange onApply={apiCalls} />}
          </div>
          {/* <Orders /> */}
        </div>
      </div>
      <div className="row">
        <div className="sales-summary-container">
          <h3 className="sales-summary-title">Daily Sales Overview</h3>
          <div className="daily-summary-chart" id="daily-summary-chart"></div>
        </div>
      </div>
    </div>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

function mapStateToProps(state) {
  return {
    dailySummary: state.insights.dailySummary ?? {},
    dailyProducts:
      state.insights.dailySummary?.daily_sales_summary?.product_sales ?? [],
    yearlySummary: state.insights.yearlySummary ?? {},
    annualOverview: state.insights.yearlySummary?.annual_overview ?? {},
    monthlyOverview: state.insights.yearlySummary?.monthly_performance ?? [],
    dailySalesOverView: state.insights.dailySalesOverview?.daily_sales ?? [],
    clients: state.procurement.clients ?? [],
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DailySalesOverview)
);
