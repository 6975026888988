import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import withRouter from "../Router/withRouter";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { addProduct, updateProduct } from "../../services/Actions/client";
import BackIcon from "../../assets/icons/back-icon.svg";
import UploadImage from "../../assets/icons/upload_image.svg";
import MenuItem from "@mui/material/MenuItem";
// import fs from "fs";

const items = [
  {
    value: "kgs",
  },
  {
    value: "pcs",
  },
  {
    value: "boxes",
  },
];

function NewProduct(props) {
  const [selectedFile, setSelectedFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const [product, setProduct] = useState(null);
  const [type, setType] = useState("");
  const [productInfo, setProductInfo] = useState({
    name: "",
    delivery_window: "",
    unit: items[0].value,
    unit_price: "",
    image: "",
    sku: "",
  });

  useEffect(() => {
    let { type, id } = props.router.params;
    setType(type);
    if (type == "edit") {
      const selectedItem = props.products.find((item) => item.id === id);
      setProduct(selectedItem);
      updateField(selectedItem);
    }
  }, []);

  const updateField = (e) => {
    let i = {
      name: e.name,
      delivery_window: e.delivery_window,
      unit: e.unit_name,
      unit_price: e.unit_price,
      image: "",
      // "sku"
    };
    setProductInfo(i);
  };
  const onUpdateProductInfo = (e) => {
    const nextFormState = {
      ...productInfo,
      [e.target.name]: e.target.value,
    };
    setProductInfo(nextFormState);
  };
  const createProduct = () => {
    const formData = new FormData();
    // console.log("seletedfile", selectedFile)
    formData.append("image", selectedFile);
    // form.append('my_file', fs.createReadStream('/foo/bar.jpg'));
    formData.append("name", productInfo.name);
    formData.append("SKU", "");
    formData.append("delivery_window", productInfo.delivery_window);
    formData.append("unit_price", productInfo.unit_price);
    formData.append("unit", productInfo.unit);
    props.dispatch(addProduct(formData)).then(() => {
      props.router.navigate(-1);
    });
  };

  const editProduct = () => {
    const formData = new FormData();
    formData.append("image", selectedFile);
    formData.append("name", productInfo.name);
    // formData.append('SKU', "");
    // formData.append('delivery_window', productInfo.delivery_window);
    formData.append("unit_price", productInfo.unit_price);
    formData.append("unit", productInfo.unit);
    props.dispatch(updateProduct(formData, product.id)).then((res) => {
      if (res) {
        props.router.navigate(-1);
      }
    });
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);

      // Preview the selected image
      const reader = new FileReader();
      reader.onloadend = () => {
        console.log("Reader", file);
        setPreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  return (
    <div className="main-container clientdetailssection">
      <div className="container">
        <div className="row">
          <div
            onClick={() => props.router.navigate(-1)}
            class="col-12 d-flex justify-content-start align-items-center"
            style={{ marginBottom: "20px" }}
          >
            <div className="gobackbuttonsection">
              <img src={BackIcon} alt="icon" className="gobackicon"></img>
              <h6>Go Back</h6>
            </div>
          </div>
          <div className="productssection"></div>
          <div className="w-full">
            <div className="clientcredentialdetailstablesection d-flex justify-content-center">
              <div className="col-lg-10 d-flex align-items-center flex-column">
                <div className="col-lg-12 d-flex align-items-center clientcredentialinputsection">
                  <h3 class="primaryheader mb-0">
                    {type == "edit" ? "UPDATE" : "NEW"} PRODUCT
                  </h3>
                </div>
                <div className="col-lg-12 d-flex align-items-center clientcredentialinputsection">
                  <div className="col-lg-5">Product Name</div>
                  <div className="col-lg-7">
                    <Box
                      className="input-box-section"
                      sx={{
                        width: "100%",
                        maxWidth: "100%",
                        backgroundColor: "#fff",
                        borderRadius: "13px",
                      }}
                    >
                      <TextField
                        name="name"
                        fullWidth
                        id="fullWidth"
                        value={productInfo.name}
                        onChange={onUpdateProductInfo}
                      />
                    </Box>
                  </div>
                </div>
                <div className="col-lg-12 d-flex align-items-center clientcredentialinputsection">
                  <div className="col-lg-5">Unit</div>
                  <div className="col-lg-7">
                    <Box
                      sx={{
                        width: "100%",
                        maxWidth: "100%",
                        backgroundColor: "#fff",
                        borderRadius: "13px",
                      }}
                    >
                      <TextField
                        name="unit"
                        select
                        fullWidth
                        id="fullWidth"
                        value={productInfo.unit || ""}
                        defaultValue={productInfo.unit || ""}
                        onChange={onUpdateProductInfo}
                      >
                        {items.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.value}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Box>
                  </div>
                </div>
                <div className="col-lg-12 d-flex align-items-center clientcredentialinputsection">
                  <div className="col-lg-5">Unit Price</div>
                  <div className="col-lg-7">
                    <Box
                      sx={{
                        width: "100%",
                        maxWidth: "100%",
                        backgroundColor: "#fff",
                        borderRadius: "13px",
                      }}
                    >
                      <TextField
                        name="unit_price"
                        fullWidth
                        id="fullWidth"
                        value={productInfo.unit_price}
                        onChange={onUpdateProductInfo}
                      />
                    </Box>
                  </div>
                </div>
                {type == "create" && (
                  <div className="col-lg-12 d-flex align-items-center clientcredentialinputsection">
                    <div className="col-lg-5">Delivery Window</div>
                    <div className="col-lg-7">
                      <Box
                        sx={{
                          width: "100%",
                          maxWidth: "100%",
                          backgroundColor: "#fff",
                          borderRadius: "13px",
                        }}
                      >
                        <TextField
                          name="delivery_window"
                          fullWidth
                          id="fullWidth"
                          value={productInfo.delivery_window}
                          onChange={onUpdateProductInfo}
                        />
                      </Box>
                    </div>
                  </div>
                )}
                <div className="col-lg-12 d-flex align-items-start clientcredentialinputsection">
                  <div className="col-lg-5">Image</div>
                  <div className="col-lg-7">
                    <Box
                      className="align-content-center d-flex flex-column align-items-center justify-content-center"
                      sx={{
                        width: "100%",
                        maxWidth: "100%",
                        height: "250px",
                        backgroundColor: "#fff",
                        borderRadius: "13px",
                        border: "1px solid #000",
                      }}
                    >
                      {/* <input type="file" accept="image/*" onChange={handleFileChange} /> */}
                      <img
                        onClick={() =>
                          document.getElementById("clickImage").click()
                        }
                        src={
                          preview
                            ? preview
                            : type == "edit"
                            ? product.image_url
                              ? product.image_url
                              : UploadImage
                            : UploadImage
                        }
                        alt={preview ? "Selected" : "icon"}
                        height={180}
                        className="gobackicon"
                      ></img>
                      <div
                        className="upload-computer"
                        style={{ color: "#F16870" }}
                      >
                        Upload from Computer
                      </div>
                      {/* <TextField name="contact_name" fullWidth id="fullWidth" value={productInfo.image} onChange={onUpdateProductInfo} /> */}
                    </Box>
                    <input
                      className="d-none"
                      id="clickImage"
                      type="file"
                      accept="image/*"
                      onChange={handleFileChange}
                    />
                  </div>
                </div>

                <div class="col-lg-12 my-5 d-flex justify-content-center">
                  <button
                    onClick={type == "edit" ? editProduct : createProduct}
                    class="nav-button-section"
                  >
                    {type == "edit" ? "UPDATE" : "CREATE"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    //   </div>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

function mapStateToProps(state) {
  return {
    products: state.product.products ?? [],
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NewProduct)
);
