import React, {useState, useEffect} from "react";
import { connect } from "react-redux";
import withRouter from "../Router/withRouter";
// import { MainHeader } from "../../Components/header";
// import Sidebar from "../../Components/sidebar";
// import Navbar from "../../Components/navbar";
// import TableCell, { tableCellClasses } from "@mui/material/TableCell";
// import { styled } from "@mui/material/styles";
import Invoice from "../Auth/invoice";
import DCDetails from "./dcDetails";
import {approveDC} from "../../services/Actions/invoices_api"


function DCApprove(props) {
  const [rows, setRows] = useState([]);

  const [total, setTotal] = useState(0);

  useEffect(() => {
    let i=props.prepareDC;
    setRows(i.challan_items)
  }, [props]);

  const onUpdateRows=(items)=>{
    console.log("items cancel",items)
  
    setRows(items);
  }
  const onApprove=()=>{
    const data = rows.map(item => ({
      item_id: item.item_id,
      quantity: item.quantity
  }));
  let payload ={
    "items": data
}
    props.dispatch(approveDC(props.prepareDC.challan.challan_id,payload))
  }


  return (
      <div className="main-container">
        <div className="container">
          <div className="row">
            <div className="productssection">
              <Invoice type="D.C" />
              <DCDetails items={rows} onUpdateRow={(items)=>onUpdateRows(items)} />
              {/* <div className="col-12 my-5 d-flex justify-content-end align-items-center">
                <div className="col-6 d-flex justify-content-end align-items-center grandtotalsection">
                  <div className="col-6 text-end"><h3>Grand Total</h3></div>
                  <div className="col-6 text-end"><h3>{getTotal()} INR</h3></div>
                </div>
              </div> */}
              <div className="col-lg-12 mt-5 d-flex justify-content-end">
                <button onClick={onApprove} className="nav-button-section">APPROVE</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    // </div>
  );
}
function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}
function mapStateToProps(state) {
  return {
    prepareDC: state.invoice.prepareDC??{}

  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DCApprove)
);
