import React, { useState, useEffect } from "react";
// import { Modal, Button } from 'react-bootstrap';/
import { Drawer, Button } from "@mui/material";
import "bootstrap/dist/css/bootstrap.min.css";
// import "../Auth/Orders.css";
import Pagination from "@mui/material/Pagination";
import withRouter from "../Router/withRouter";
import { connect } from "react-redux";

import {
  Table,
  TableBody,
  // TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Stack,
} from "@mui/material";

import { FaPlus, FaMinus } from "react-icons/fa";

import "./modelpop.css";
// import NoItems from "../../Components/no_items";
import NoItems from "../Components/no_items";


const ModalPop = (props) => {
  const [rows, setRows] = useState([]);
  const [mainProducts, setMainProducts] = useState([]);
  const [allRows, setProducts] = useState([]);

  const [isOpen, setIsOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [value, setValue] = useState("");

  const toggleDrawer = (open) => (event) => {
    console.log("event.key", event.key, event.type);
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setIsOpen(open);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const onSearch = (e) => {
    let value = e.target.value;
    setValue(value);
  };
  useEffect(() => {
    setMainProducts(props.products);
    setRows(props.suggestions);
    setProducts(props.suggestions);
    console.log("level of the mension");
  }, [props]);

  // const handleQuantityChange = (index, change, item) => {
  //   let { dispatch } = props;
  //   const newRows = allRows.map((row, i) =>
  //     row.id === item.id
  //       ? { ...row, suggested_quantity: row.suggested_quantity + change }
  //       : row
  //   );

  //   setRows(newRows);
  // };

  const handleQuantityChange = (index, change, item) => {
    // Ensure `suggested_quantity` always remains at a valid state
    console.log("click Action");
    const newRows = rows.map((row) =>
      row.id === item.id
        ? {
            ...row,
            suggested_quantity: Math.max(0, row.suggested_quantity + change),
          }
        : row
    );

    setRows(newRows);

    // If dispatching additional actions, ensure it happens after state update
    // if (props.dispatch) {
    //   props.dispatch({ type: "QUANTITY_UPDATED", payload: newRows });
    // }
  };

  const handleInputChange = (event, row) => {
    const value = event.target.value;
    const newQuantity = value === "" ? "" : Math.max(0, Number(value) || 0);

    console.log("section log", newQuantity);
    const updatedRows = rows.map((r) =>
      r.id == row.id ? { ...r, suggested_quantity: newQuantity } : r
    );
    setRows(updatedRows);
  };
  const moveToCart = () => {
    const updatedMainArray = mainProducts.map((item) => {
      const updateItem = rows.find(
        (update) =>
          update.id === item.id ||
          (update.name ?? "").toLowerCase() === item.name.toLowerCase()
      );

      return updateItem
        ? { ...item, qty: updateItem.suggested_quantity }
        : item;
    });
    props.dispatch({
      type: "GET_ORDER_PRODUCTS",
      data: updatedMainArray,
    });
    toggleDrawer(false);
    // props.router.navigate("/dashboard/1");
    let clientId=props.router.params.clientId;
    let tab=props.router.params.tab;

    props.router.navigate(`/dashboard/menuorders/cart/${clientId}`, { replace: true })

  };

  const removeItem = (item) => {
    let filterItems = rows.filter((i) => i.id != item.id);
    setRows(filterItems);
  };

  return (
    <div className="demo">
      <div className="col-12 d-flex justify-content-between align-items-center suggestionbuttondivsection">
        <button onClick={toggleDrawer(true)} className="button-section">
          Suggestion
        </button>
      </div>
      {/* <Button onClick={toggleDrawer(true)}>Open Drawer</Button> */}

      <Drawer
        anchor="right"
        open={isOpen}
        onClose={toggleDrawer(false)}
        style={{ width: "35%" }}
      >
        <div
          style={{ padding: 20 }}
          role="presentation" className="suggestionpopupsection"
          // onClick={toggleDrawer(false)}
          // onKeyDown={toggleDrawer(false)}
        >
          <div className="align-items-center d-flex justify-content-between">
            <h3 className="commonheadertextsection">Suggestion Products</h3>
            <div onClick={toggleDrawer(false)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="#D9D9D9"
                className="bi bi-x-circle"
                viewBox="0 0 16 16"
              >
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
              </svg>
            </div>
          </div>
          {rows.length > 0 && (
            <div className="row col-lg-12 d-flex align-items-center justify-content-between">
              <div className="commonsearchdivsection">
                <div className="inputseachsection">
                  <input
                    className="form-control mr-sm-2"
                    type="search"
                    placeholder="Search..."
                    aria-label="Search"
                    onChange={onSearch}
                  />
                </div>
              </div>
            </div>
          )}
          {rows.length > 0 ? (
            <div className="col-12 suggestionproductsection" style={{ width: "100%" }}>
              {rows &&
                rows
                  ?.filter((item) =>
                    value
                      ? item.name.toLowerCase().includes(value.toLowerCase())
                      : true
                  )
                  .slice((page - 1) * rowsPerPage, page * rowsPerPage)
                  .map((row, index) => (
                    <div
                      className="productdetailssection col-lg-12 d-flex align-items-center justify-content-between"
                      key={row.name}
                    >
                      <div className="d-flex align-items-center">
                        <div onClick={() => removeItem(row)}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            fill="#D9D9D9"
                            className="bi bi-x-circle closeiconsection"
                            viewBox="0 0 16 16"
                          >
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                          </svg>
                        </div>
                        <div className="modelImageSection">
                          <img
                            src={row.image_url}
                            alt="img"
                            className="modelImage"
                          ></img>
                        </div>
                        <div className="modelProductName">
                          <div>
                            <h3>{row.name}</h3>
                          </div>
                          <div>
                            <h5>Units: {row.unit}</h5>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="cartproductquantity d-flex align-items-center justify-content-center">
                          <span>
                            <FaMinus
                              onClick={() =>
                                handleQuantityChange(index, -1, row)
                              }
                              style={{
                                cursor:
                                  row.suggested_quantity > 1
                                    ? "pointer"
                                    : "not-allowed",
                                color:
                                  row.suggested_quantity > 1 ? "black" : "grey",
                              }}
                            />
                          </span>
                          <span>
                            <input
                              type="text"
                              className="quantityinputsection modelinputsection"
                              style={{ width: "52px" }}
                              value={row.suggested_quantity}
                              onChange={(e) => handleInputChange(e, row)}
                            />
                          </span>
                          <span>
                            <FaPlus
                              onClick={() =>
                                handleQuantityChange(index, 1, row)
                              }
                              style={{ cursor: "pointer" }}
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}
            </div>
          ) : (
            <NoItems />
          )}
          <div className="col-lg-12 d-flex align-items-center justify-content-between myorderscontainerdivsection orderandcartsection">
            <Stack spacing={2}>
              <Pagination
                count={Math.ceil(
                  rows.filter((item) =>
                    value
                      ? item.name.toLowerCase().includes(value.toLowerCase())
                      : true
                  ).length / rowsPerPage
                )}
                page={page}
                onChange={handleChangePage}
                showFirstButton
                showLastButton
              />
            </Stack>
          </div>
          {rows.length > 0 && (
            <div className="col-12 d-flex justify-content-between align-items-center mt-3">
              <button onClick={moveToCart} className="button-section">
                Move to Cart
              </button>
            </div>
          )}
        </div>
      </Drawer>
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}
function mapStateToProps(state) {
  return {
    suggestions: state.product.suggestions ?? [],
    products: state.product.orderProducts??[],

  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ModalPop)
);
