import React from "react";
import { connect } from "react-redux";
import withRouter from "../Router/withRouter";
import { MainHeader } from "../../Components/header";
import Sidebar from "../../Components/sidebar";
import Clientcredentialdetails from "./clientcredentialdetails.js";
import BackIcon from "../../assets/icons/back-icon.svg";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

function Clientcredential(props) {
  return (
    <div className="w-full">
      {/* <div className="headerandsidebar">
        <MainHeader />
        <Sidebar />
      </div> */}
      <div className="main-container clientdetailssection">
        <div className="container">
          <div className="row">
            <div
              onClick={() => props.router.navigate(-1)}
              class="col-12 d-flex justify-content-start align-items-center"
              style={{ marginBottom: "20px" }}
            >
              <div className="gobackbuttonsection">
                <img src={BackIcon} alt="icon" className="gobackicon"></img>
                <h6>Go Back</h6>
              </div>
            </div>
            <div className="productssection">
              <Clientcredentialdetails />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}
function mapStateToProps(state) {
  return {};
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Clientcredential)
);
