import React, { useEffect } from "react";

// import { connect } from "react-redux";
// import withRouter from "../Pages/Router/withRouter";

// import Box from "@mui/material/Box";
// import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

function LinkTab(props) {
  return (
    <Tab
      component="a"
      onClick={(event) => {
        event.preventDefault();
        props.onClick(event, props.value);
      }}
      {...props}
    />
  );
}

export default LinkTab;