import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import withRouter from "../Router/withRouter";
import BackIcon from "../../assets/icons/back-icon.svg";
import EditIcon from "../../assets/icons/edit.svg";
import Invoicetable from "./invoicetable";
import Form from "react-bootstrap/Form";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import {
  getListOfInvoices,
  updatePassword,
} from "../../services/Actions/client";
import DCDetailsTable from "../Dc/dcDetailsTable";
import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import CusDropDownFilter from "../../Components/cusDropDownFilter";
import CusDateRange from "../../Components/cusDateRange";
import moment from "moment/moment";

function Clientinvoicedetails(props) {
  const [client, setClient] = useState(null);
  const [invoices, setClientInvoices] = useState({});
  const [clientDc, setClientDc] = useState([]);

  const [value, setValue] = useState("");
  const [tableType, setTableType] = useState("D.C");
  const [dateRange, setDateRange] = useState(7);

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [passwords, setPasswords] = useState({
    newPassword: "",
    oldPassword: "",
  });
  const [selectedValue, setSelectedValue] = useState("invoice");

  useEffect(() => {
    let clients = props.clients ?? [];
    let clientId = props.router?.params?.id ?? "";
    let item = clients.find((i) => i.id === clientId);
    console.log("Final props vale", item);
    setClient(item);
    //  getInvoicesDetail(clientId)
  }, [props]);

  useEffect(() => {
    const id = props.router?.params?.id ?? "";
    if (selectedValue != "D.C") {
      // getDcDetail(id)
      // }else{
      passDate(id, dateRange);
    }
  }, []);

  const handleChange = (event) => {
    let val = event.target.value;
    const id = props.router?.params?.id ?? "";

    if (val != "D.C") {
      //   getDcDetail(id)
      // }else{
      passDate(id, dateRange);
    }
    setSelectedValue(val);
  };
  const getInvoicesDetail = (id) => {
    props.dispatch(getListOfInvoices(id)).then((data) => {
      setClientInvoices(data);
    });
  };
  // const getDcDetail =(id)=>{
  //   props.dispatch(getListOfDc(id)).then((data)=>{
  //     setClientDc(data);
  //     console.log("asdasdasdas", data);
  //   });;
  // }
  const onUpdatePassword = (e) => {
    const nextFormState = {
      ...passwords,
      [e.target.name]: e.target.value,
    };
    setPasswords(nextFormState);
  };
  const changePassword = () => {
    let payload = {
      new_password: passwords.newPassword,
      confirm_new_password: passwords.newPassword,
      current_password: passwords.oldPassword,
    };
    props.dispatch(updatePassword(payload));
  };
  const passDate = (days) => {
    const id = props.router?.params?.id ?? "";

    const today = moment();
    const laststart = today.clone().subtract(days, "days");
    const from = laststart.format("YYYY-MM-DD");

    const to = today.format("YYYY-MM-DD");
    getInvoicesDetail(id, from, to);
  };
  const onSelect = (val) => {
    if (val != 0) {
      passDate(client?.id ?? "", val);
    }
    setDateRange(val);
  };
  const applyDateFilter = (from, to) => {
    const id = props.router?.params?.id ?? "";

    getInvoicesDetail(id, from, to);
  };
  return (
    <div className="w-full">
      <div className="main-container">
        <div className="container">
          <div className="row clientinvoicedetailssection d-flex justify-content-center">
            <div className="col-lg-12 d-flex mb-5">
              <div
                className="col-lg-4"
                style={{ borderRight: "2px solid #8C7E7E" }}
              >
                <div
                  onClick={() => props.router.navigate(-1)}
                  class="col-12 d-flex justify-content-start align-items-center clientinvoicedetailsgobacksection"
                  style={{ marginBottom: "20px" }}
                >
                  <div className="gobackbuttonsection">
                    <img src={BackIcon} alt="icon" className="gobackicon"></img>
                    <h6>Go Back</h6>
                  </div>
                </div>
                <div className="clientprofilepicturesection"></div>
                <div className="clientprofiledetailsection">
                  <h3>{client?.company_name ?? ""}</h3>
                  {/* <h3>Name of the Restaurant</h3> */}
                  <h3
                    className="accountnumbersection"
                    style={{ marginTop: "20px" }}
                  >
                    Account Number
                  </h3>
                  <h5>Email: {client?.email ?? ""}</h5>
                  <h5>Phone : {client?.phone_number ?? ""}</h5>
                </div>
                <div className="clientaddressdetailsection">
                  <h3>Address</h3>
                  <h5>
                    {client?.shipping_address?.address_line_1 ?? ""},{" "}
                    {client?.shipping_address?.address_line_2 ?? ""}
                  </h5>
                </div>
                <div className="col-lg-12 text-center editclientbuttonsection">
                  <button
                    onClick={() =>
                      props.router.navigate(
                        "/dashboard/clientcredential/edit/" + client?.id ?? ""
                      )
                    }
                    class="nav-button-section active"
                  >
                    Edit Client
                  </button>
                </div>
                <div className="changepasswordsection d-flex align-items-center mt-5">
                  <h3 className="m-0">Change Password</h3>
                  <img src={EditIcon} alt="icon" className="editicon"></img>
                </div>
                <div className="col-10 my-3 currentpasswordsection">
                  <Form.Control
                    onChange={onUpdatePassword}
                    value={passwords.oldPassword}
                    name="oldPassword"
                    size="lg"
                    type="text"
                    placeholder="Current Password"
                  />
                </div>
                <div className="col-10 my-3 newpasswordsection">
                  <Form.Control
                    onChange={onUpdatePassword}
                    value={passwords.newPassword}
                    name="newPassword"
                    size="lg"
                    type="text"
                    placeholder="New  Password"
                  />
                </div>
                <div className="col-lg-12 text-center editdetailsbuttonsection">
                  <button
                    onClick={changePassword}
                    class="nav-button-section active"
                  >
                    Update
                  </button>
                </div>
              </div>

              <div className="col-lg-8" style={{ padding: "0 20px" }}>
                <div className="col-lg-12 d-flex align-items-center justify-content-between invoiceanddcradiobuttondivsections">
                  <FormControl className="invoiceanddcradiobuttonsections">
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={selectedValue}
                      onChange={handleChange}
                    >
                      <FormControlLabel
                        value="invoice"
                        control={<Radio />}
                        label="Invoice"
                      />
                      <FormControlLabel
                        value="D.C"
                        control={<Radio />}
                        label="D.C"
                      />
                    </RadioGroup>
                  </FormControl>
                </div>

                {selectedValue !== "D.C" && (
                  <div className="invoiceanddccustomrangesection">
                    <div className="d-flex align-items-center customdaterangesection">
                      <CusDropDownFilter onSelect={onSelect} />
                    </div>
                    <div>
                      {dateRange == 0 && (
                        <CusDateRange onApply={applyDateFilter} />
                      )}
                    </div>
                  </div>
                )}
                {selectedValue != "D.C" && (
                  <div className="col-lg-12 d-flex align-items-center justify-content-between">
                    <div className="col-lg-4 totalinvoicesection invoicesdashboardsection">
                      <h3>${(invoices.total ?? 0).toLocaleString()}</h3>
                      <h3>Invoices Total</h3>
                    </div>
                    <div className="col-lg-4 paidinvoicessection invoicesdashboardsection paidinvoicesandpendinginvoicessection">
                      <h3 style={{ color: "#007B5F" }}>
                        ${(invoices.paid ?? 0).toLocaleString()}
                      </h3>
                      <h3>Paid Invoices</h3>
                    </div>
                    <div className="col-lg-4 pendinginvoicessection invoicesdashboardsection paidinvoicesandpendinginvoicessection">
                      <h3 style={{ color: "#F79250" }}>
                        ${(invoices.pending ?? 0).toLocaleString()}
                      </h3>
                      <h3>Pending Invoices</h3>
                    </div>
                  </div>
                )}
                {/* <div className="col-lg-12" style={{marginTop: "30px"}}>
              <div className="inputseachsection col-8">
                <input
                  className="form-control mr-sm-2"
                  type="search"
                  placeholder="Search Products..."
                  aria-label="Search"
                />
              </div>
            </div> */}

                {selectedValue === "D.C" ? (
                  <DCDetailsTable data={clientDc ?? []} />
                ) : (
                  <Invoicetable data={invoices?.invoices ?? []} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

function mapStateToProps(state) {
  return {
    clients: state.procurement.clients ?? [],
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Clientinvoicedetails)
);
