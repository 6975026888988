import React, { useState } from "react";
import { connect } from "react-redux";
import withRouter from "../Pages/Router/withRouter";

import { MenuItem, Select, FormControl } from "@mui/material";

function CusDropDownFilter(Props) {

    const [tableFilter, setTableFilter] = useState(7);
    const handleOptionsChange = (event) => {
        let val = event.target.value;
        Props.onSelect(val);
        setTableFilter(val);
    }
    return (
        <div className="col-lg-2 ">
        <FormControl variant="outlined" size="medium" fullWidth={true}>
          {/* <InputLabel>Rows per page</InputLabel> */}
          <Select
            value={tableFilter}
            onChange={handleOptionsChange}
            sx={{
              height: 36, // Adjust the height here (e.g., 36px)
              fontSize: "0.875rem", // Optional: Adjust font size for consistent appearance
            }}
            // label="Rows per page"
          >
            <MenuItem value={7}>Last 7 days</MenuItem>
            <MenuItem value={14}>Last 2 weeks</MenuItem>
            <MenuItem value={30}>Last 30 days</MenuItem>
            <MenuItem value={0}>Custom Range</MenuItem>
          </Select>
        </FormControl>
      </div>
    );
}

function mapDispatchToProps(dispatch) {
    return {
      dispatch,
    };
  }
  
  function mapStateToProps(state) {
    return {
    //   sellingPrices: state.procurement.sellingPrices??{}
  
    };
  }
  
  export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(CusDropDownFilter)
  );