import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import withRouter from "../Router/withRouter";
import { MainHeader } from "../../Components/header";
import Sidebar from "../../Components/sidebar";
import Navbar from "../../Components/navbar";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Checkbox from "@mui/material/Checkbox";
import Sellingprice from "./sellingprice";
import {
  getSellingPrices,
  updateSellingPrice,
  getClients,
} from "../../services/Actions/client";
import DatePicker from "react-datepicker";
import moment from "moment/moment";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import NoItems from "../Components/no_items";
import { Calculate } from "@mui/icons-material";
import { InputLabel } from "@mui/material";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

function HomeSellingPrize(props) {
  const [rows, setRows] = useState({});
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [startDate, setStartDate] = useState(new Date());
  const [starttime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [selectAll, setSelectAll] = useState(false);
  const [selected, setSelected] = useState([]);
  const [selectedClient, setClients] = useState("");

  // CalculatePrice
  const [costprice, setCost] = useState(0);
  const [sellingPrice, setSelling] = useState(0);
  const [totalPrice, setPrice] = useState(0);
  const [totalPercentage, setPercentage] = useState(0);

  useEffect(() => {
    // getData(startDate);
    props.dispatch(getClients());
  }, []);
  useEffect(() => {
    if (props.clients.length > 0) {
      setClients(props.clients[0].id);
      getData();
    }
  }, [props.clients]);

  useEffect(() => {
    setRows(props.sellingPrices);
  }, [props.sellingPrices]);

  const handleSelectAllClick = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
  };
  const handleSelectChange = (event) => {
    let id = event.target.value;
    setClients(id);
  };
  const getData = () => {
    const from = moment(startDate).format("YYYY-MM-DD");
    props.dispatch(getSellingPrices(selectedClient, from));
  };

  const handleMonthChange = (date, setDate) => {
    setDate(date);
    // getData(date);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleItemSelection = (newSelected) => {
    setSelected(newSelected);
    setSelectAll(newSelected.length > 0 && newSelected.length === 2); // Adjust based on your total rows
  };
  const onUpdateRows = (key, items) => {
    let updatedRows = {
      ...rows,
      [key]: items,
    };
    console.log("updatedRows0,", updatedRows);
    setRows(updatedRows);
  };
  const onUpdatePrice = () => {
    let result = [];
    Object.values(rows)
      .flat()
      .map((item) => {
        if (item.selling_price != 0) {
          result.push({
            product_id: item.product_id,
            order_ids: item.order_ids,
            selling_price: item.selling_price,
          });
        }
      });
    console.log("result", result);
    let payload = {
      prices: result,
      update_existing: true,
    };
    props.dispatch(updateSellingPrice(payload));
  };

  useEffect(() => {
    const cost = Object.values(rows)
      .flat()
      .reduce(
        (total, item) =>
          total + (item?.cost_price ?? 0) * (item?.quantity ?? 0),
        0
      );

    const selling = Object.values(rows)
      .flat()
      .reduce(
        (total, item) =>
          total + (item?.selling_price ?? 0) * (item?.quantity ?? 0),
        0
      );

    const price = selling - cost;
    const percentage = cost !== 0 ? (price / cost) * 100 : 0;

    setCost(cost.toFixed(2));
    setSelling(selling.toFixed(2));
    setPrice(price.toFixed(2));
    setPercentage(percentage.toFixed(2));
  }, [rows]);

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value);
    setPage(1); // Reset to the first page when rows per page changes
  };

  // const getTotalCost=()=>{
  //   let result = 0;
  //   // cost_price
  //   // selling_price
  //   Object.values(rows)
  //     .flat()
  //     .map((item) => {
  //       result=(item?.cost_price??0)+result;
  //     });
  //     return result.toFixed(2);
  // }

  // const getTotalSelling=()=>{
  //   let result = 0;
  //   Object.values(rows)
  //     .flat()
  //     .map((item) => {
  //       result=(item?.selling_price??0)+result;
  //     });
  //     return result.toFixed(2);
  // }

  // const getTotalPrice=()=>{
  //   let result = 0;
  //   let result1 = 0;
  //   Object.values(rows)
  //     .flat()
  //     .map((item) => {
  //       result=(item?.cost_price??0)+result;
  //       result1=(item?.selling_price??0)+result1;
  //     });
  //     return (result1-result).toFixed(2);
  // }

  return (
    // <div className="w-full">
    //   <div className="headerandsidebar">
    //     <MainHeader />
    //     <Sidebar />
    //   </div>
    //   <Navbar />
    <div className="main-container">
      <div className="container">
        <div className="row">
          <div className="productssection">
            <div className="col-12 d-flex justify-content-between mb-3">
              <div>
                <FormControl
                  className="customdropdownsection"
                  sx={{ m: 1, minWidth: 450 }}
                >
                  <Select
                    value={selectedClient}
                    onChange={handleSelectChange} // Updated to handleSelectChange
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    {props.clients?.map((i) => (
                      <MenuItem value={i.id}>{i.name ?? ""}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="fromDateSection col-3 d-flex align-items-center me-5">
                {/* <h5 className="me-3">From</h5> */}

                <DatePicker
                  selected={startDate}
                  placeholderText="dd/mm/yyyy"
                  onChange={(date) => handleMonthChange(date, setStartDate)}
                  // onYearChange={(date) => handleMonthChange(date)}

                  dateFormat="dd/MM/yyyy"
                  // showMonthYearPicker
                  className="form-control"
                />
              </div>
              {/* <div className="startTimeSection col-3 d-flex align-items-center me-5">

<DatePicker
  selected={starttime}
  onChange={(date) => handleMonthChange(date, setStartTime)}
  showTimeSelect
  showTimeSelectOnly
  placeholderText="start time"
  dateFormat="HH:mm"
  className="form-control"
/>
</div>
<div className="endTimeSection col-3 d-flex align-items-center me-5">

<DatePicker
  selected={endTime}
  onChange={(date) => handleMonthChange(date, setEndTime)}
  showTimeSelect
  showTimeSelectOnly
  placeholderText="end time"
  dateFormat="HH:mm"
  className="form-control"
/>
</div> */}

              <div className="d-flex justify-content-between align-items-center">
                <button onClick={getData} className="button-section">
                  Apply
                </button>
              </div>
            </div>
            {Object.entries(rows).length > 0 ? (
              <div>
                {/* Search Input */}
                <div className="col-lg-12 d-flex align-items-center justify-content-between mb-5">
                  <div className="inputseachsection col-4">
                    <input
                      className="form-control mr-sm-2"
                      type="search"
                      placeholder="Search..."
                      aria-label="Search"
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  </div>
                  
                </div>
                <div
                  className="d-flex align-items-center"
                  style={{ marginLeft: "10px" }}
                >
                  <Checkbox
                    {...label}
                    checked={selectAll}
                    onChange={handleSelectAllClick}
                  />
                  <h4
                    className="selectalltextsection"
                    onClick={handleSelectAllClick}
                    style={{ cursor: "pointer" }}
                  >
                    Select all
                  </h4>
                </div>
                {Object.entries(rows).map(([key, value]) => {
                  return (
                    <div>
                      <Sellingprice
                        selectAll={selectAll}
                        onUpdateRow={(key, items) => onUpdateRows(key, items)}
                        handleItemSelection={handleItemSelection}
                        name={key}
                        items={value.filter((product) =>
                          search
                            ? product.name
                                .toLowerCase()
                                .includes(search.toLowerCase())
                            : true
                        )}
                      />
                      {/* <Stack
                        spacing={2}
                        className="d-flex justify-content-between align-items-center flex-row"
                      >
                        <div className="col-6">
                          <FormControl variant="outlined" size="small">
                            <InputLabel>Rows per page</InputLabel>
                            <Select
                              defaultValue={rowsPerPage}
                              value={rowsPerPage}
                              onChange={handleRowsPerPageChange}
                              label="Rows per page"
                            >
                              <MenuItem selected={rowsPerPage == 10} value={10}>
                                10
                              </MenuItem>
                              <MenuItem value={20}>20</MenuItem>
                              <MenuItem value={50}>50</MenuItem>
                              <MenuItem value={100}>100</MenuItem>
                            </Select>
                          </FormControl>
                        </div>
                        <div className="col-6 d-flex justify-content-end">
                          <Pagination
                            count={Math.ceil(
                              rows.filter((item) =>
                                value
                                  ? item.name
                                      .toLowerCase()
                                      .includes(value.toLowerCase())
                                  : true
                              ).length / rowsPerPage
                            )}
                            page={page}
                            onChange={handleChangePage}
                            showFirstButton
                            showLastButton
                          />
                        </div>
                      </Stack> */}
                    </div>
                  );
                })}
                {/* <Sellingprice
                selectAll={selectAll}
                handleItemSelection={handleItemSelection}
              />
              <Sellingprice
                selectAll={selectAll}
                handleItemSelection={handleItemSelection}
              />
              <Sellingprice
                selectAll={selectAll}
                handleItemSelection={handleItemSelection}
              />
              <Sellingprice
                selectAll={selectAll}
                handleItemSelection={handleItemSelection}
              /> */}
                <div className="col-lg-12 mt-5 d-flex justify-content-end align-items-center orderdetailssection">
                  <div className="col-lg-6 d-flex justify-content-end align-items-center flex-column">
                    {/* <div className="col-12">
                  <h3>Order Details :</h3>
                </div> */}
                    <div className="col-12 d-flex justify-content-between align-items-center mb-2">
                      <p>Total Cost Price:</p>
                      <p>{costprice}</p>
                    </div>
                    <div className="col-12 d-flex justify-content-between align-items-center mb-2">
                      <p>Total Selling Price:</p>
                      <p>{sellingPrice}</p>
                    </div>
                    <div className="col-12 d-flex justify-content-between align-items-center mb-2">
                      <p>Total Gross Price</p>
                      <p>{totalPrice}</p>
                    </div>
                    <div className="col-12 d-flex justify-content-between align-items-center mb-2">
                      <p>Total Gross Percentage</p>
                      <p>{totalPercentage}%</p>
                    </div>
                    {/* <div className="col-12 d-flex justify-content-between align-items-center mt-3">
                  <button className="button-section">Place Order</button>
                </div> */}
                  </div>
                </div>
                <div className="d-flex justify-content-end orderandcartsection">
                    <Stack spacing={2}>
                      <Pagination
                        count={Math.ceil(
                          Object.keys(rows).length / rowsPerPage
                        )}
                        page={page}
                        onChange={handleChangePage}
                        showFirstButton
                        showLastButton
                      />
                    </Stack>
                  </div>
                <div className="col-lg-12 mt-5 d-flex justify-content-end">
                  <button
                    onClick={onUpdatePrice}
                    className="nav-button-section"
                  >
                    Update Price
                  </button>
                </div>
              </div>
            ) : (
              <NoItems />
            )}
          </div>
        </div>
      </div>
    </div>
    // </div>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

function mapStateToProps(state) {
  return {
    sellingPrices: state.procurement.sellingPrices ?? {},
    clients: state.procurement.clients ?? [],
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HomeSellingPrize)
);
