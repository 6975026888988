const initialState = {
    yearlySummary: {},
    dailySummary: {},
    operational: [],
    };
    
    const insightsReducer = (state = initialState, action) => {
      console.log("action",action)
      switch (action.type) {
              case "YEARLY_SUMMARY":
              return Object.assign({}, state, {
                yearlySummary: action.data,
              });
              break;
              case "DAILY_SALES_OVERVIEW":
              return Object.assign({}, state, {
                dailySalesOverview: action.data,
              });
              break;
              case "DAILY_SUMMARY":
                return Object.assign({}, state, {
                  dailySummary: action.data,
                });
                break;
                case "OPERATIONAL_LIST":
                  return Object.assign({}, state, {
                    operational: action.data,
                  });
                  break;
                  case "SET_OPERATIONAL":
                  return Object.assign({}, state, {
                    setOperational: action.data,
                  });
                  break;
        default:
          return state;
      }
    };
    
    export default insightsReducer;