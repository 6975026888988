import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import withRouter from "../Router/withRouter";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import CusDropDownFilter from "../../Components/cusDropDownFilter";
import CusDateRange from "../../Components/cusDateRange";

function createData(number, sku_number, items, unit, quantity, price, total) {
  return { number, sku_number, items, unit, quantity, price, total };
}

// const rows = [
//   createData(1, "4984758", "Red Onions", "KG", 10, 40, 400),
//   createData(2, "6849359", "Tomatoes", "KG", 4, 20, 80),
// ];

function Invoicedetails(props) {
  const [rows, setRows] = useState([]);

  useEffect(() => {
    // let i=props.prepareInvoices;
    setRows(props.items);
  }, [props.items]);

  const getTotal = (i) => {
    let prize;
    prize = i.unit_price * i.quantity;
    return prize;
  };
  const handleInputChange = (e, rowIndex) => {
    const updatedRows = rows.map((row, index) => {
      if (index === rowIndex) {
        return { ...row, unit_price: parseFloat(e.target.value) || 0 };
      }
      return row;
    });
    setRows(updatedRows);

    if (props.onUpdateRow) {
      props.onUpdateRow(updatedRows);
    }
  };
  return (
    <div className="w-full">
      <div className="invoicedetailstablesection">
        <TableContainer component={Paper} style={{ borderRadius: "20px" }}>
          <div style={{ margin: "10px 50px" }}>
            <Table
              sx={{ minWidth: 650 }}
              aria-label="simple table"
              style={{ backgroundColor: "transparent !important" }}
            >
              <TableHead>
                <TableCell align="left" width={50}>
                  No.
                </TableCell>
                <TableCell align="center">SKU No.</TableCell>
                <TableCell align="center">Items</TableCell>
                <TableCell align="center">Unit</TableCell>
                <TableCell align="center">Quantity</TableCell>
                <TableCell align="center">Price</TableCell>
                <TableCell align="center">Total</TableCell>
              </TableHead>
              <TableBody>
                {rows &&
                  rows.map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell align="left">{index}</TableCell>
                      <TableCell align="center">{row.sku}</TableCell>
                      <TableCell align="center">{row.name}</TableCell>
                      <TableCell align="center">{row.unit}</TableCell>
                      <TableCell align="center">{row.quantity}</TableCell>
                      <TableCell align="center">
                        <div className="pricedivsection">
                          {" "}
                          <span>
                            <input
                              type="number"
                              className="quantityinputsection"
                              value={row.unit_price || ""}
                              onChange={(e) => handleInputChange(e, index)}
                              min="1"
                            />
                          </span>
                        </div>
                      </TableCell>
                      <TableCell align="center">{getTotal(row)}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </div>
        </TableContainer>
      </div>
    </div>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

function mapStateToProps(state) {
  return {
    prepareInvoices: state.procurement.prepareInvoices ?? {},
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Invoicedetails)
);
