// import service from "service";
import axios from "axios";
import { API } from "../apis";
import service from "./axios_service";
import { toast } from "react-toastify";
import moment from "moment";


const getSuggesstions = (id) => {
    const currentDayName = moment().format('dddd');

    let url = `${API.BASE_URL1}admin/suggestions/${currentDayName}?client_id=${id}`;
    return dispatch => {
        service.get(url)
            .then((res) => {
                dispatch({
                    type: "GET_SUGGESTIONS",
                    data: res.data?.suggestions??[],
                });
            })
            .catch((error) => {
            })
    }
}


export {
    getSuggesstions
  };
  