import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import withRouter from "../Router/withRouter";
import OrderNavbar from "../../Components/orderNavBar";
import { ImportExportTwoTone } from "@mui/icons-material";
import Carts from "./cart_screen";
import Orders from "./order_screen";
import {
  getAdminProducts,
  getProductsTrends,
  getClients,
} from "../../services/Actions/client";
import { getSuggesstions } from "../../services/Actions/products";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { use } from "react";

function OrderHome(props) {
  const [value, setValue] = React.useState("");
  const [selectedClient, setClients] = useState("");

  useEffect(() => {
    let tab = props.router.params.tab;
    setValue(props.router.params.tab);
    let clientId = props.router.params.clientId;
    if (clientId) {
      console.log("Client Section", clientId);
      setClients(clientId);
    } else {
      console.log("Clients", props.clients);
      if (props.clients.length > 0) {
        let clientId1 = props.clients[0]?.id;
        setClients(props.clients[0]?.id);
        console.log("Client", clientId1);
        props.router.navigate(`/dashboard/menuorders/${tab}/${clientId1}`, {
          replace: true,
        });
      }
    }

    // setValue(props.router.params.clientId);
  }, [props]);
  useEffect(() => {
    if (selectedClient) {
      props.dispatch(getSuggesstions(selectedClient));
    }
  }, [selectedClient]);

  useEffect(() => {
    props.dispatch(getClients());

    props.dispatch(getAdminProducts());
    // props.dispatch(getSuggesstions());
  }, []);
  const handleSelectChange = (event) => {
    let id = event.target.value;
    setClients(id);
    props.router.navigate(`/dashboard/menuorders/${value}/${id}`, {
      replace: true,
    });
  };
  return (
    <div className="w-full">
      <div className="main-container">
        {/* <div className="headerandsidebar">
          <MainHeader />
          <Sidebar />        
        </div> */}
        <div className="container">
          <div className="row">
            <div
              className="col-12 d-flex justify-content-between align-items-center my-5 analyticsyeardropdownsection"
            >
              <div>
                <FormControl
                  className="customdropdownsection"
                  sx={{ m: 1, minWidth: 450 }}
                >
                  <Select
                    value={selectedClient}
                    onChange={handleSelectChange} // Updated to handleSelectChange
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    {props.clients?.map((i) => (
                      <MenuItem value={i.id}>{i.name ?? ""}</MenuItem>
                    ))}
                    {/* <MenuItem value={20}>Customers</MenuItem> */}
                  </Select>
                </FormControl>
              </div>
              {/* <div>
                <CustomYearSelect
                  value={selectedYear}
                  onChange={onChangeYear}
                />
              </div> */}
            </div>
          </div>
        </div>
        <OrderNavbar />
        {value == "cart" ? <Carts /> : <Orders />}
      </div>
    </div>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

function mapStateToProps(state) {
  return {
    //   sellingPrices: state.procurement.sellingPrices??{}
    clients: state.procurement.clients ?? [],
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OrderHome)
);
