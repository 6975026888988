import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import withRouter from "../Router/withRouter";
import { MainHeader } from "../../Components/header";
import Sidebar from "../../Components/sidebar";
import Navbar from "../../Components/navbar";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import Orders from "./orders";
import { getOrders, getClients } from "../../services/Actions/client";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import DatePicker from "react-datepicker";
import moment from "moment";
import CusDropDownFilter from "../../Components/cusDropDownFilter";
import CusDateRange from "../../Components/cusDateRange";
import { InputLabel, OutlinedInput } from "@mui/material";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

function HomeOrders(props) {
  const [rows, setRows] = useState({});
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [dateRange, setDateRange] = useState(7);
  const [selectedClient, setClients] = useState("");

  const [invoices, setInvoices] = useState([]);
  const [tableFilter, setTableFilter] = useState(7);

  // const [startDate, setStartDate] = useState(null);
  // const [endDate, setEndDate] = useState(null);
  const [openModal, setOpenModal] = useState(false); // State to control modal

  const [search, setSearch] = useState("");

  useEffect(() => {
    // getInvoicesApi("", "");
    callDropDownFilter(tableFilter);
  }, []);

  useEffect(() => {
    // downloadInvoice();
    setInvoices(props.invoices);
  }, [props]);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const onSearch = (e) => {
    let value = e.target.value;
    setSearch(value);
  };
  // const handleMonthChange = (date, setDate) => {
  //   setDate(date);
  // };
  // const clearDate = () => {
  //   setStartDate(null);
  //   setEndDate(null);
  // };
  const applyFilter = (date1, date2) => {
    const from = date1.format("YYYY-MM-DD");

    const to = date2.format("YYYY-MM-DD");
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value);
    setPage(1); // Reset to the first page when rows per page changes
  };
  const handleOptionsChange = (event) => {
    let val = event.target.value;
    if (val == 0) {
      // handleCloseModal();
      setDefaultDate();
    } else {
      callDropDownFilter(val);
      // setTableFilter(val);
    }
    setTableFilter(val);
  };
  const callDropDownFilter = (val) => {
    const today = moment();
    const laststart = today.clone().subtract(val, "days");
    applyFilter(laststart, today);
  };

  const handleCloseModal = () => {
    setOpenModal(!openModal);
    // setSelectedOrder(null);
  };

  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  // useEffect(() => {
  //   const today = new Date();
  //   const tomorrow = new Date();
  //   tomorrow.setDate(today.getDate() + 1);

  //   setFromDate(formatDate(today));
  //   setToDate(formatDate(tomorrow));
  // }, []);

  const setDefaultDate = () => {
    const today = new Date();
    const tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 1);

    setFromDate(formatDate(today));
    setToDate(formatDate(tomorrow));
  };

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const handleFromdateChange = (e) => {
    const newFromDate = e.target.value;
    setFromDate(newFromDate);

    if (new Date(newFromDate) >= new Date(toDate)) {
      const updatedTodate = new Date(newFromDate);
      updatedTodate.setDate(updatedTodate.getDate() + 1);
      setToDate(formatDate(updatedTodate));
    }
  };

  const handleTodateChange = (e) => {
    const newToDate = e.target.value;
    setToDate(newToDate);

    if (new Date(newToDate) <= new Date(fromDate)) {
      const updatedFromdate = new Date(newToDate);
      updatedFromdate.setDate(updatedFromdate.getDate() - 1);
      setFromDate(formatDate(updatedFromdate));
    }
  };

  const onClickApply = () => {
    console.log("Date Range", fromDate, toDate);
    applyFilter(moment(fromDate), moment(toDate));
  };

  useEffect(() => {
    // props.dispatch(getOrders());
    if (props.clients.length > 0) {
      let id = props.clients[0].id;
      setClients(id);
      getDate(id, dateRange);
    }
  }, [props.clients]);
  useEffect(() => {
    let clientId = props.router.params.clientId;
    if (clientId != undefined && clientId != "") {
      setClients(clientId);
      getDate(clientId, dateRange);
    }
  }, [props.router.params]);
  useEffect(() => {
    props.dispatch(getClients());
    const currentDate = moment();
    currentDate.subtract(2, "days").format("YYYY-MM-DD");
    setStartDate(currentDate.toDate());
  }, []);
  const handleMonthChange = (date, setDate) => {
    setDate(date);
    // getData(date);
  };
  const handleSelectChange = (event) => {
    let id = event.target.value;
    setClients(id);
    getDate(id, dateRange);
  };
  // const onApply=()=>{
  //   getDate(selectedClient);
  // }
  const getDate = (id, days) => {
    const today = moment();
    const laststart = today.clone().subtract(days, "days");
    const from = laststart.format("YYYY-MM-DD");

    const to = today.format("YYYY-MM-DD");
    props.dispatch(getOrders(id, from, to));
  };
  const onSelect = (val) => {
    if (val != 0) {
      getDate(selectedClient, val);
    }
    setDateRange(val);
  };
  const applyDateFilter = (from, to) => {
    props.dispatch(getOrders(selectedClient, from, to));
  };
  // const callDateFiltter = (val) => {
  //   const today = moment();
  //   const laststart = today.clone().subtract(val, "days");
  //   applyFilter(laststart, today);
  // };
  return (
    // <div className="w-full">
    //   <div className="headerandsidebar">
    //     <MainHeader />
    //     <Sidebar />
    //   </div>
    //   <Navbar />
    <div className="main-container">
      <div className="container">
        <div className="row">
          <div className="productssection">
            {/* <div className="d-flex">
              <div>
                <FormControl
                  className="customdropdownsection"
                  sx={{ m: 1, minWidth: 400 }}
                >
                  <Select
                    value={selectedClient}
                    onChange={handleSelectChange} 
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem value="">All</MenuItem>
                    {props.clients?.map((i) => (
                      <MenuItem value={i.id}>{i.name ?? ""}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <CusDropDownFilter onSelect={onSelect} />
              {dateRange == 0 && <CusDateRange onApply={applyDateFilter} />}
            </div> */}
            <div className="col-lg-12 d-flex align-items-center justify-content-between invoice-header-section">
            <div>
                <FormControl
                  className="customdropdownsection"
                  sx={{ m: 1, minWidth: 400 }}
                >
                  <Select
                    value={selectedClient}
                    onChange={handleSelectChange} 
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem value="">All</MenuItem>
                    {props.clients?.map((i) => (
                      <MenuItem value={i.id}>{i.name ?? ""}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="col-lg-2">
                <FormControl variant="outlined" size="medium" fullWidth={true}>
                  {/* <InputLabel>Rows per page</InputLabel> */}
                  <Select
                    value={tableFilter}
                    onChange={handleOptionsChange}
                    sx={{
                      height: 36, // Adjust the height here (e.g., 36px)
                      fontSize: "0.875rem", // Optional: Adjust font size for consistent appearance
                    }}
                    // label="Rows per page"
                  >
                    <MenuItem value={7}>Last 7 days</MenuItem>
                    <MenuItem value={14}>Last 2 weeks</MenuItem>
                    <MenuItem value={30}>Last 30 days</MenuItem>
                    <MenuItem value={0}>Custom Range</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
            {tableFilter == 0 && (
              <div className="reservation-box col-lg-12">
                <div className="static common-div-section">
                  <div className="input-container h-25">
                    <label htmlFor="fromdate">From Date</label>
                    <input
                      type="date"
                      id="fromdate"
                      value={fromDate}
                      onChange={handleFromdateChange}
                    />
                  </div>
                </div>
                <div className="flex common-div-section">
                  <div className="input-container h-25">
                    <label htmlFor="todate">To Date</label>
                    <input
                      type="date"
                      id="todate"
                      value={toDate}
                      onChange={handleTodateChange}
                    />
                  </div>
                </div>
                <div className="flexSSSS">
                  <button className="button-section greyButton">Clear</button>
                </div>
                <div className="flex">
                  <button onClick={onClickApply} className="button-section">
                    Apply
                  </button>
                </div>
              </div>
            )}
            <Orders />
          </div>
        </div>
      </div>
      {" "}
    </div>
  );
}
function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}
function mapStateToProps(state) {
  return {
    orders: state.procurement.orders ?? [],
    clients: state.procurement.clients ?? [],
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HomeOrders)
);
