import React, { useEffect } from "react";

import { connect } from "react-redux";
import withRouter from "../Pages/Router/withRouter";

import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import LinkTab from "./linkTab";


 function Navbar(props) {
  const [value, setValue] = React.useState(0);

  const handleChange = (newValue) => {
    let clientId=props.router.params.clientId;
    console.log("clientId val",newValue,clientId);
    props.router.navigate(`/dashboard/menuorders/${newValue}/${clientId}`, { replace: true });
    setValue(newValue);
  };

  useEffect(() => {
    setValue(props.router.params.tab);
  }, [props]);


  return (
    <div className="w-full">
      <div className="">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <Box sx={{ width: "100%" }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="nav tabs example"
                >
                  <div className="container mx-0 px-0">
                    <div className="row mx-0 px-0">
                      <div className="col-lg-12 d-flex align-items-center justify-content-around">
                        <LinkTab
                          label="ORDERS"
                          className={`nav-button-section ${
                            value === "orders" ? "active" : ""
                          }`}
                          value={"orders"}
                          onClick={() => handleChange("orders")}
                        />
                        <LinkTab
                          label="CART"
                          className={`nav-button-section ${
                            value === "cart" ? "active" : ""
                          }`}
                          value={"cart"}
                          onClick={() => handleChange("cart")}
                        />
                      </div>
                    </div>
                  </div>
                </Tabs>
              </Box>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}



function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

function mapStateToProps(state) {
  return {
    sellingPrices: state.procurement.sellingPrices??{}

  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Navbar)
);